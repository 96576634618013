import APIManager from './APIManager';

// ? Minor to Individual Account Transfer
const FillLOCForm = async (queryObj) => {
    const formData = new FormData();

    formData.append('sDistrict', queryObj.sDistrict);
    formData.append('sMunicipality', queryObj.sMunicipality);
    formData.append('sWardNo', queryObj.sWardNo);
    formData.append('sGrandfatherName', queryObj.sGrandfatherName);
    formData.append('sFatherName', queryObj.sFatherName);
    formData.append('sAge', queryObj.sAge);
    formData.append('sRelation', queryObj.sRelation);
    formData.append('sName', queryObj.sName);
    formData.append('companyName', queryObj.companyName);
    formData.append('shareQuantity', queryObj.shareQuantity);
    formData.append('rMunicipality', queryObj.rMunicipality);
    formData.append('rDistrict', queryObj.rDistrict);
    formData.append('rGrandfatherName', queryObj.rGrandfatherName);
    formData.append('rFatherName', queryObj.rFatherName);
    formData.append('rAge', queryObj.rAge);
    formData.append('rRelation', queryObj.rRelation);
    formData.append('rName', queryObj.rName);
    formData.append('securities', JSON.stringify(queryObj.securities));
    formData.append('name', queryObj.name);
    formData.append('mobileNo', queryObj.mobileNo);
    formData.append('email', queryObj.email);
    formData.append('signature', queryObj.signature);
    formData.append('rightTP', queryObj.rightTP);
    formData.append('leftTP', queryObj.leftTP);
    const formResponse = APIManager.axios
        .post(`/api/v1/public/letter-of-consent`, formData)
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FillLOCForm,
};
