<template>
  <v-container class="my-10 mb-8 pb-8 dcl-form">
    <v-dialog v-model="submitDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ resMessage.title || 'Your form has been submitted' }}</v-card-title>
        <v-card-text>
          <p>{{ resMessage.message || 'You will get a copy of your form in the email you provided' }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitLoader" max-width="500px">
      <v-card>
        <v-card-title>Your form is being submitted</v-card-title>
        <v-card-text>
          <p>Your form is being processed. This might take some time...</p>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-snackbar v-model="snackBar.show" :timeout="3000" :color="snackBar.color" elevation="24" location="bottom right">
      <span class="snackbar__message">{{ snackBar.text }}</span>
    </v-snackbar>

    <div class="dcl__header">
      <h2><v-btn class="me-2" icon href="javascript:history.back()"><v-icon>mdi-chevron-left</v-icon></v-btn> Letter of
        Consent (मन्जुरीनामा)</h2>
      <v-divider class="my-8"> </v-divider>
    </div>

    <div class="form-container">
      <v-form v-model="valid" ref="STForm">
        <v-container>
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <section class="pb-2">
                <h3>Transferrer's Details</h3>
                <p class="muted">Individual performing the transfer</p>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferrer Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Joseph Doe" density="compact"
                  v-model="clientInfo.sName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer Age -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferrer's Age
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 21" density="compact" v-model="clientInfo.sAge"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer Grandfather Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferrer's Grandfather's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. George Doe" density="compact"
                  v-model="clientInfo.sGrandfatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer Father Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferrer's Father's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jonathan Doe" density="compact"
                  v-model="clientInfo.sFatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer District -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferrer's District
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Kathmandu" density="compact"
                  v-model="clientInfo.sDistrict" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer Municipality -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferrer's Municipality
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="Kathmandu" density="compact"
                  v-model="clientInfo.sMunicipality" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer Ward Number -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferrer's Ward Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 10" density="compact" v-model="clientInfo.sWardNo"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer - Applicant Relation -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Applicant's Relation with Transferrer
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Sibling" density="compact"
                  v-model="clientInfo.sRelation" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <section class="pb-2">
                <h3>Transferee's Details </h3>
                <p class="muted">Individual receiving the transfer</p>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferee Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferee's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Joseph Doe " density="compact"
                  v-model="clientInfo.rName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferee Age -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferee's Age
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 23 " density="compact" v-model="clientInfo.rAge"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferrer Father Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferee's Father Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jonathan Doe " density="compact"
                  v-model="clientInfo.rFatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferee Großvater Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferee's Grandfather Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. George Doe" density="compact"
                  v-model="clientInfo.rGrandfatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferee District -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferee's District
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Lalitpur" density="compact"
                  v-model="clientInfo.rDistrict"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Transferee Municiaplity -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Transferee's Municipality
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Patan" density="compact"
                  v-model="clientInfo.rMunicipality" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Applicant - Transferee Relation -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Applicant's Relation with Transferee
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Sibling " density="compact"
                  v-model="clientInfo.rRelation" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>


          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <section class="pb-2">
                <h3>Securities Details</h3>
                <p class="muted">Details of Share/Securities being Transferred</p>
              </section>
            </v-col>
          </v-row>

          <!-- ? Company Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Company Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Acme Co." density="compact"
                  v-model="clientInfo.companyName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Share Quantity -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Share Quantity
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 50" density="compact"
                  v-model="clientInfo.shareQuantity" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <!-- ? Aktien -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Securities</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="20%">
                        SN
                      </th>
                      <th class="text-left" width="40%">
                        Script Name
                      </th>
                      <th class="text-left" width="40%">
                        Script Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sec, index in clientInfo.securities" :key="index">
                      <td>{{ sec.sn }}</td>
                      <td>{{ sec.companyName }}</td>
                      <td>{{ sec.scriptQuantity }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row class="mt-4">
                <v-col cols="12" sm="12" md="6">
                  <v-text-field variant="outlined" label="Company Name" density="compact"
                    v-model="security.companyName"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field variant="outlined" label="Script Name" density="compact"
                    v-model="security.scriptQuantity"></v-text-field>
                </v-col>
              </v-row>
              <v-btn style="width: 100%; text-transform: capitalize; letter-spacing: 0px;" elevation="0" color="success"
                @click="addSecurity"><v-icon left>mdi-plus</v-icon> Add</v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center mt-10">
            <v-col cols="12" md="10">
              <section class="pb-2">
                <h3>Applicant Details</h3>
                <p class="muted">The individual filling the form/ For whom the form is being filled for.</p>
              </section>
            </v-col>
          </v-row>

          <!-- ? Applicant's Name  -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Applicant's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. John Doe " density="compact" v-model="clientInfo.name"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <!-- ? Applicant Mobile Number -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Applicant's Mobile/ Telephone Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 98XXXXXXXX " density="compact"
                  v-model="clientInfo.mobileNo" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Applicant Email Address -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Applicant's Email Address
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. j.doe@gmail.com " density="compact"
                  v-model="clientInfo.email" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Applicant Signature -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Signature</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" placeholder="Upload Signature in JPG or PNG format" flat density="compact"
                append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense v-model="clientInfo.signature"
                :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Client Signature -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Right Thumb Print</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" placeholder="Upload Thumbprint in JPG or PNG format" flat density="compact"
                append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense v-model="clientInfo.rightTP"
                :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Client Signature -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Left Thumb Print</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" placeholder="Upload Thumbprint in JPG or PNG format" flat density="compact"
                append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense v-model="clientInfo.leftTP"
                :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Submit -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <v-btn elevation="0" color="success" style="text-transform: capitalize; letter-spacing: 0px;"
                  @click="submit">Submit
                  <v-icon right>mdi-content-save</v-icon></v-btn>
              </section>
            </v-col>
          </v-row>

        </v-container>

      </v-form>
    </div>
  </v-container>
</template>

<script>
import LOCService from "../services/API/LOCService"
export default {
  name: "LOC",
  data() {
    return {
      valid: false,
      submitDialog: false,
      submitLoader: false,
      snackBar: {
        show: false,
        color: '',
        text: ''
      },
      resMessage: {
        title: null,
        message: null
      },
      security: {
        sn: '',
        companyName: '',
        shareQuantity: '',
      },
      clientInfo: {
        sDistrict: '',
        sMunicipality: '',
        sWardNo: '',
        sGrandfatherName: '',
        sFatherName: '',
        sAge: '',
        sRelation: '',
        sName: '',
        companyName: '',
        shareQuantity: '',
        rDistrict: '',
        rMunicipality: '',
        rGrandfatherName: '',
        rFatherName: '',
        rAge: '',
        rRelation: '',
        rName: '',
        securities: [],
        name: '',
        mobileNo: '',
        email: '',
        signature: '',
        rightTP: '',
        leftTP: ''
      },
      stringRules: [
        v => !!v || "This field is required",
      ],
      stringSizeRule: [
        v => !!v && v.size < 1000000 || 'File is required and the file size should not exceed 1MB!'
      ],
      sizeRule: [
        v => (v ? v.size < 1000000 : true) || 'File size should not exceed 1MB!'
      ]
    }
  },
  methods: {
    addSecurity() {

      if (this.security.companyName && this.security.scriptQuantity) {
        this.clientInfo.securities.push({
          sn: this.clientInfo.securities.length + 1,
          companyName: this.security.companyName,
          scriptQuantity: this.security.scriptQuantity
        });
        this.security.companyName = ''
        this.security.scriptQuantity = ''
      } else {
        this.snackBar.show = true;
        this.snackBar.color = 'red';
        this.snackBar.text = 'Please enter the necessary information'
      }
    },
    async submit() {
      if (this.$refs.STForm.validate() && this.clientInfo.securities.length > 0) {
        this.submitLoader = true;
        await LOCService.FillLOCForm(this.clientInfo).then((response) => {
          if (response.status != 200) {
            this.resMessage.title = "Could Not Submit Form"
            this.resMessage.message = "There was an error while submitting this form"
          }

          this.submitLoader = false;
          this.submitDialog = true;
        })
      } else {
        this.snackBar.show = true;
        this.snackBar.color = 'red';
        this.snackBar.text = 'Fill all the fields and add atleast one security info for transfer.'
      }

    }
  }
}
</script>

<style scoped>
.form-container {
  max-width: 800px;
  margin-inline: auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.4rem;
  padding-block: 2rem;
}

.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}

.dcl__header {
  max-width: 800px;
  margin-inline: auto;
}
</style>
